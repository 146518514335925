var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableNodes,"footer-props":{
    itemsPerPageOptions: [10, 20, 50, 100, -1]
  },"sort-desc":_vm.sorting.desc,"sort-by":_vm.sorting.by,"items-per-page":_vm.nodeTableItems,"item-key":"node_id"},on:{"update:sortDesc":function($event){return _vm.$set(_vm.sorting, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sorting, "desc", $event)},"update:sortBy":function($event){return _vm.$set(_vm.sorting, "by", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sorting, "by", $event)},"update:itemsPerPage":function($event){_vm.nodeTableItems=$event},"update:items-per-page":function($event){_vm.nodeTableItems=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-1","text":""},nativeOn:{"click":function($event){return _vm.resetFilter()}}},[_vm._v("Reset Filter")])]},proxy:true},{key:"header.node_id",fn:function(ref){
  var header = ref.header;
return [_c('filter-options',{attrs:{"items":_vm.ids},on:{"update:items":function($event){_vm.ids=$event}},model:{value:(_vm.filters.ids),callback:function ($$v) {_vm.$set(_vm.filters, "ids", $$v)},expression:"filters.ids"}}),_vm._v("\n    "+_vm._s(header.text)+"\n  ")]}},{key:"header.type",fn:function(ref){
  var header = ref.header;
return [_c('filter-options',{attrs:{"items":_vm.types},on:{"update:items":function($event){_vm.types=$event}},model:{value:(_vm.filters.types),callback:function ($$v) {_vm.$set(_vm.filters, "types", $$v)},expression:"filters.types"}}),_vm._v("\n    "+_vm._s(header.text)+"\n  ")]}},{key:"header.product",fn:function(ref){
  var header = ref.header;
return [_c('filter-options',{attrs:{"items":_vm.products},on:{"update:items":function($event){_vm.products=$event}},model:{value:(_vm.filters.products),callback:function ($$v) {_vm.$set(_vm.filters, "products", $$v)},expression:"filters.products"}}),_vm._v("\n    "+_vm._s(header.text)+"\n  ")]}},{key:"header.name",fn:function(ref){
  var header = ref.header;
return [_c('filter-options',{attrs:{"items":_vm.names},on:{"update:items":function($event){_vm.names=$event}},model:{value:(_vm.filters.names),callback:function ($$v) {_vm.$set(_vm.filters, "names", $$v)},expression:"filters.names"}}),_vm._v("\n    "+_vm._s(header.text)+"\n  ")]}},{key:"header.loc",fn:function(ref){
  var header = ref.header;
return [_c('filter-options',{attrs:{"items":_vm.locations},on:{"update:items":function($event){_vm.locations=$event}},model:{value:(_vm.filters.locations),callback:function ($$v) {_vm.$set(_vm.filters, "locations", $$v)},expression:"filters.locations"}}),_vm._v("\n    "+_vm._s(header.text)+"\n  ")]}},{key:"header.secure",fn:function(ref){
  var header = ref.header;
return [_c('filter-options',{attrs:{"items":_vm.secures},on:{"update:items":function($event){_vm.secures=$event}},model:{value:(_vm.filters.secures),callback:function ($$v) {_vm.$set(_vm.filters, "secures", $$v)},expression:"filters.secures"}}),_vm._v("\n    "+_vm._s(header.text)+"\n  ")]}},{key:"header.status",fn:function(ref){
  var header = ref.header;
return [_c('filter-options',{attrs:{"items":_vm.states},model:{value:(_vm.filters.states),callback:function ($$v) {_vm.$set(_vm.filters, "states", $$v)},expression:"filters.states"}}),_vm._v("\n    "+_vm._s(header.text)+"\n  ")]}},{key:"header.lastActive",fn:function(ref){
  var header = ref.header;
return [_c('filter-options',{attrs:{"items":_vm.lastActives},on:{"update:items":function($event){_vm.lastActives=$event}},model:{value:(_vm.filters.lastActives),callback:function ($$v) {_vm.$set(_vm.filters, "lastActives", $$v)},expression:"filters.lastActives"}}),_vm._v("\n    "+_vm._s(header.text)+"\n  ")]}},{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('tr',{style:({
        cursor: 'pointer',
        background:
          _vm.selectedNode === item ? _vm.$vuetify.theme.themes.light.accent : 'none'
      }),on:{"click":function($event){$event.stopPropagation();return _vm.nodeSelected(item)}}},[_c('td',[_vm._v(_vm._s(item.node_id))]),_vm._v(" "),_c('td',{staticClass:"td-large"},[_vm._v(_vm._s(item.type))]),_vm._v(" "),_c('td',{staticClass:"td-large",attrs:{"title":_vm.productName(item)}},[_vm._v("\n        "+_vm._s(_vm.productName(item))+"\n      ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.name || ''))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.loc || ''))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.secure ? 'Yes' : 'No'))]),_vm._v(" "),_c('td',{staticClass:"td-medium"},[_vm._v(_vm._s(item.status))]),_vm._v(" "),_c('td',[_vm._v("\n        "+_vm._s(item.lastActive
            ? new Date(item.lastActive).toLocaleString()
            : 'Never')+"\n      ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }